import moment from "moment";
import PivotMediaPlaylist from "./PivotMediaPlaylist";
import MediaThumbnail from "./MediaThumbnail";
import Widget from "./Widget";

export enum MediaSize {
    large   = "large",
    medium  = "medium",
    small   = "small"
}

export enum MediaPreview {
    normal      = "normal",
    clip        = "clip",
}

export function isImage(mime_type: string | null | undefined): boolean {
    return mime_type?.startsWith("image/") ?? false;
}

export function isVideo(mime_type: string | null | undefined): boolean {
    return mime_type?.startsWith("video/") ?? false;
}

export function getThumbnail(resource: Media | Widget | null, name: string){
    if(resource){
        if('thumbnails' in resource){
            let url = resource.full_path;
            if(resource.thumbnails.length > 0){
                url = resource.thumbnails.find(item => item.name === 'system_hp_printer')?.full_path ?? resource.full_path;
            }
            return url;
        } 
    }
    return '';
}

export enum MediaFilter {
    all     = "all",
    image   = "image",
    video   = "video",
}

export function getAllMediaFilters(): string[] {
    return Object.values(MediaFilter);
}

export function ifShowMediabyFilter(media: Media, filter: string): boolean {
    if(filter === MediaFilter.all) return true;
    return media.mime_type.startsWith(`${filter}/`) ?? false;
}

export function getSecondsFormattedIn(seconds: number, format: string = "mm:ss"): string {
    return moment.utc(seconds*1000).format(format);
}

export function getDimensionFormatted(media: Media | null): string | null {

    if(!media) return null;
    
    if(media.width && media.height) return `${media.width} x ${media.height}`;

    return null;

}

export function getMediaDuration(value: number): number {
    return value / 1000;
}

export function setMediaDuration(value: number): number {
    return value * 1000;
}

export default interface Media {
    id: string;
    name: string;
    user_id: string;
    original_name: string;
    mime_type: string;
    size: number;
    size_str: string;
    thumbnails: MediaThumbnail[];
    path: string;
    full_path: string;
    file_duration: number;
    stored_id: string;
    visibility: string;
    description: string;
    updated_at: string;
    created_at: string;
    order?: number;
    pivot?: PivotMediaPlaylist;
    width?: number;
    height?: number;
};
