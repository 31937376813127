import * as actionTypes from '../actions/types';
import EInk from '../models/EInk';
import PaginateResponse from '../services/PaginateResponse';

export interface EInkReducerState {
   readonly data: PaginateResponse<EInk[]> | null;
   readonly isLoading: boolean;
}

const INITIAL_STATE: EInkReducerState = {
   data: null,
   isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {

   switch(action.type) {
      case actionTypes.EINK_FETCH:
            
         return {
            ...state,
            data: action.payload.data,
            isLoading: false,
         }

      case actionTypes.EINK_FETCH_NEXT:

         let actualEinks: EInk[] = state?.data?.data ?? [];
         let payload: PaginateResponse<EInk[]> = action.payload.data;
         let newEinks: any = payload.data ?? [];

         if(action.payload.current_page <= state?.data?.current_page) {
            return {
                ...state,
            }
         }

         newEinks = actualEinks.concat( newEinks );
         payload.data = newEinks;

         return {
            ...state,
            data: payload,
            isLoading: false,
         }

      case actionTypes.EINK_CHANGE_VALUE:
         return {
            ...state,
            [action.payload.key]: action.payload.value,
         }

      default:
         return state;
   }

}
