import * as actionTypes from '../actions/types';
import ScreenGroup from '../models/ScreenGroup';
import PaginateResponse from '../services/PaginateResponse';

export interface ScreenGroupReducerState {
    readonly data: PaginateResponse<ScreenGroup[]> | null;
    readonly isLoading: boolean;
}

const INITIAL_STATE: ScreenGroupReducerState = {
    data: null,
    isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.SCREENGROUP_FETCH:
            
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
            }

        case actionTypes.SCREENGROUP_FETCH_NEXT:

            let actualScreenGroups: ScreenGroup[] = state?.data?.data ?? [];
            let payload: PaginateResponse<ScreenGroup[]> = action.payload.data;
            let newScreenGroup: any = payload.data ?? [];

            if(action.payload.current_page <= state?.data?.current_page) {
                return {
                    ...state,
                }
            }

            newScreenGroup = actualScreenGroups.concat( newScreenGroup );
            payload.data = newScreenGroup;

            return {
                ...state,
                data: payload,
                isLoading: false,
            }

        case actionTypes.SCREENGROUP_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        default:
            return state;
        
    }
}
