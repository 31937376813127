import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';

import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import ScreenService from '../../services/use_case/screen/ScreenService';
import ApiResponse from '../../services/ApiResponse';
import { ScreenInputs } from '../Screen/Screen';
import Screen from '../../models/Screen';

import { 
    CactusLabelInput, 
    DashboardTitle, 
    DashboardWrapper, 
    ScreenPairCode, 
    ModalDeleteConfirm 
} from '../../components';

interface ScreenDetailProps {};

const ScreenDetail: React.FC<ScreenDetailProps> = (props: ScreenDetailProps) => {

    const [t] = useTranslation();

    const _screenService:ScreenService = new ScreenService()

    let { id: screenId } = useParams<{id:string}>() 
    const [isLoading, setisLoading] = useState(false)
    const [isUpdatingForm, setisUpdatingForm] = useState(false)
    const [deletingScreen, setdeletingScreen] = useState(false)
    const [screen, setscreen] = useState<Screen>()
    const [title, settitle] = useState<string>(t('Screen'))

    let history = useHistory()
    
    const { handleSubmit, register, errors, reset } = useForm<ScreenInputs>({
        defaultValues: {
            ...screen
        }
    });
    const textValidator = register(useFormValidatorText());

    useEffect(() => {
        _getScreen(screenId)
        return () => {
            _screenService.cancelRequest()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getScreen = async( id:string ) => {
        setisLoading(true)

        try {
            const screenResponse:AxiosResponse<ApiResponse<Screen>> = await _screenService.show(id);
            const screen:Screen = screenResponse.data.data!
            setisLoading(false)
            setscreen(screen)
            reset(screen)
            settitle(t('Screen') + ': ' + screen?.name)
        } catch (error) {
            history.replace('/screens')
        }

    }

    const _deleteScreen = async() => {
        setisUpdatingForm(true)
        try {
            await _screenService.delete(screenId)
            setisUpdatingForm(false)
            setdeletingScreen(false)
            history.replace('/screens')
        } catch (error) {
            setisUpdatingForm(false)
        }
    }

    const _handleOnSubmit =  async(data:FormData) => {

        setisUpdatingForm(true)
        try {
            const updateResponse:AxiosResponse<ApiResponse<Screen>> = await _screenService.update(screenId, data)
            console.log('updateResponse', updateResponse)
            setisUpdatingForm(false)
            setscreen(updateResponse.data.data!)
            settitle(t('Screen') + ': ' + updateResponse.data.data!.name)
        } catch(error) {
            setisUpdatingForm(false)
        }
        
    }

    const _renderPairCode = () => {
        return (
            <div className="col-md-6 mx-auto">
                <div className="card">
                    <div className="card-body">
                        <ScreenPairCode paircode={screen?.pair_code} />
                        <div className="text-center">
                            { _renderDeleteButton() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const _renderScreenDetailBody = () => {
        return (
            
            screen?.paircode_linked ? _renderScreenForm() : _renderPairCode()

        )
    }

    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className="btn btn-cactus mt-2"
                disabled={isUpdatingForm ?? false}
            >
                {t('Update_screen')}
            </button>
        );
    }

    const _renderDeleteButton = () => {
        return (
            <button 
                type="submit"
                onClick={ () => setdeletingScreen(true) }
                className="btn btn-cactus--red mt-2"
                disabled={isUpdatingForm ?? false}
            >
                {t('Delete_screen')}
            </button>
        )
    }
    
    const _renderScreenForm = () => {

        return (
            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit(_handleOnSubmit)} >
                        <div className="row">
                            <div className="col-sm-6">
                                <CactusLabelInput
                                    labelTitle={t('Name')}
                                    inputAttributes={{
                                        id: "name",
                                        name: "name",
                                        type:  "text",
                                        placeholder: t('Name'),
                                        autoComplete: "name",
                                        ref: textValidator,
                                    }}
                                    errorMessage={errors.name ? errors.name.message : null}
                                />
                            </div>
                            <div className="col-sm-6">
                                <CactusLabelInput
                                    labelTitle={t('License_id')}
                                    inputAttributes={{
                                        name: "hardware_key",
                                        disabled: true,
                                        classprops: 'disabled',
                                        ref: register,
                                    }}
                                    errorMessage={errors.name ? errors.name.message : null}
                                />
                            </div>
                            <div className="col-12 text-right">
                                { _renderBtnSubmit() }
                            </div>
                        </div>
                    </form>

                    <div className="col-12 text-right">
                        { _renderDeleteButton() }
                    </div>
                    
                </div>
            </div>
        )
        
    }
    

    return(
        <DashboardWrapper>
            <div className="row">
                <div className="col-6">
                    <DashboardTitle title={title} isLoading={isLoading}  />
                </div>
                <div className="col-12">
                    {
                        !isLoading && _renderScreenDetailBody()
                    }
                </div>
            </div>

            <ModalDeleteConfirm 
                show={deletingScreen}
                title={t('Delete_screen')}
                onCloseFunction={setdeletingScreen}
                onCloseFunctionParameter={false}
                entityTranslation={t('screen')}
                buttonLoading={isUpdatingForm}
                onClickFunctionButton={_deleteScreen}
                buttonText={t('Delete')}
            />
            
        </DashboardWrapper>
    );

};

export default React.memo(ScreenDetail);
