import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Navigate, ToolbarProps } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const CustomCalendarToolBar: React.FC<any> = (props:ToolbarProps) => {

    let lang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? "en";
    moment.locale(lang);

    const [t] = useTranslation();

    const [viewState, setViewState] = useState('day');

    const [scheduleDate, setScheduleDate] = useState(new Date());

    const goToDayView = () => {
        props.onView('day');
        setViewState('day');
    };
    const goToWeekView = () => {
        props.onView('week');
        setViewState('week');
    };
    
    const goToSpecificDate = (newDate: Date) => {
        props.onNavigate(Navigate.DATE, newDate);
    };

    return(
            <div className="rbc-toolbar">
                <div className="rbc-btn-group">
                    <DatePicker 
                        todayButton="TODAY" 
                        selected={props.date} 
                        onChange={goToSpecificDate}
                        locale={lang}
                        dateFormat="dd-MM-yyyy"
                    />
                </div>
                <span className="rbc-toolbar-label">{moment(props.date).format('DD/MM/YYYY')}</span>
                <div className="rbc-btn-group">
                    <button type="button" onClick={goToWeekView}>Week</button>
                    <button type="button" onClick={goToDayView}>Day</button>
                </div>
            </div>
    );

};

export default React.memo(CustomCalendarToolBar);
