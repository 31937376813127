import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Screen, { isScreenActive, isScreenNotActive } from '../../models/Screen';
import { CustomSelect } from "../index";
import Option from "../../models/Option";
import Playlist, { getOption } from "../../models/Playlist";

interface ScreenItemProps {
    screen: Screen;
    defaultOption: Option[];
    onChange?: (screen: Screen, option: Option) => void;
};

const ScreenItem: React.FC<ScreenItemProps> = (props: ScreenItemProps) => {

    const [t] = useTranslation();
    const [value, setValue] = useState<Option | null>(null);

    useEffect(() => {
        setValue( _getDefaultValue() );
    }, []);

    const _getDefaultValue = (): Option | null => {
        let playlist: Playlist | null = props.screen.linked ?? null;

        if(!playlist) return null;

        return getOption(playlist);

    }

    const _screenPlayingTitle = () => {

        if(isScreenActive(props.screen)) {
            return t('Now_playing');
        }
        else if(isScreenNotActive(props.screen)) {
            return t('Select Playlist');
        }
        
        return "";
    }

    return(
        <div className="card mb-3">
            <div className="card-body screen-item">
                <Link to={`screens/${props.screen.id}`} className="screen-item__name" >
                    <i className="iconsminds-monitor"></i>
                    <span className="ml-2">{ props.screen.name }</span>
                </Link>
                <div className="screen-item__playing">
                    <div className="screen-item__playing__title">
                        { _screenPlayingTitle() }
                    </div>
                    <div className="screen-item__playing">
                        <CustomSelect
                            options={props.defaultOption}
                            value={value}
                            onChange={(selectedOption: Option) => {
                                setValue(selectedOption);
                                props.onChange?.(props.screen, selectedOption);
                            }}
                        />
                    </div>
                </div>
                {/* <div className="screen-item__status">
                    {
                        ( Math.round(Math.random()) === 1 )?
                        <span className="badge badge-success">{t('ONLINE')}</span>:
                        <span className="badge badge-danger">{t('OFFLINE')}</span>
                    }
                    
                </div> */}
                <div className="screen-item__options">
                    <i className="iconsminds-gear pointer"></i>
                </div>
            </div>
        </div>
    );

};

export default React.memo(ScreenItem);
