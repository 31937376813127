import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import EInkModel from '../../models/EInk';
import Option from "../../models/Option";
import styled from "styled-components";

import ApiResponse from '../../services/ApiResponse';
import EInkService from '../../services/use_case/eink/EInkService';

import { 
    DashboardTitle,
    DashboardWrapper,
    CustomSelect,
} from '../../components';

interface EInkDetailProps {};

const EInkDetail: React.FC<EInkDetailProps> = (props: EInkDetailProps) => {

    const [t] = useTranslation();
    let { id: einkID } = useParams<{id:string}>();
    const [isLoading, setisLoading] = useState(false);
    const [isLoadingButton, setisLoadingButton] = useState(false);
    const [eink, setEInk] = useState<EInkModel>();
    const [selectedPicture, setSelectedPicture] = useState<string | null | undefined>(null);

    const noRotation: Option = {label: 'No Rotation', value: '0'};
    const [rotationOption] = useState<Option[]>([
        noRotation,
        {label: 'Rotate Left', value: '-90'},
        {label: 'Rotate Right', value: '90'},
        {label: 'Rotate 180', value: '180'},
    ]);
    const [selectRotationOption, setSelectRotationOption] = useState<Option>(noRotation);

    const imageContainerRef = useRef<any>(null);
    const imageRef = useRef<any>(null);
    const inputChangeImageRef = useRef<HTMLInputElement>(null);

    let history = useHistory();

    const _einkService: EInkService = new EInkService();

    useEffect(() => {
        _getEInk(einkID);

        return () => {
            _einkService.cancelRequest()
        }

    }, []);

    const _getEInk = async( id: string ) => {
        setisLoading(true);

        try {
            const einkResponse: AxiosResponse<ApiResponse<EInkModel>> = await _einkService.show(id);
            const einkData: EInkModel = einkResponse.data.data!;
            setisLoading(false);
            setEInk(einkData);
        }
        catch (error) {
            history.replace('/eink');
        }
    }

    const _renderLeftBody = () => {
        return(
            <>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <div><strong>{t('Name')}:</strong> {eink?.name}</div>
                                <hr style={{background: '#96969652'}}/>
                                <div className='mt-3'><strong>{t('Model')}:</strong> -</div>
                                <hr style={{background: '#96969652'}}/>
                                <div className='mt-3'><strong>{t('Serial No')}:</strong> -</div>
                                <hr style={{background: '#96969652'}}/>
                                <div className='mt-3'><strong>{t('Panel Info')}:</strong> -</div>
                                <hr style={{background: '#96969652'}}/>
                                <div className='mt-3'><strong>{t('Firmware Version')}:</strong> -</div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div ref={imageContainerRef} className="eink-screen">
                                    <div className="eink-screen__plug"></div>
                                    <img
                                        ref={imageRef}
                                        className="eink-screen__img"
                                        src={selectedPicture || ""}
                                        alt=""
                                        style={{transform: `rotate(${selectRotationOption.value ?? 0}deg)` }}
                                    />
                                </div>
                                <div className="mt-3" style={{ width: '100%', maxWidth: '300px', margin: '0 auto'}}>
                                    <CustomSelect
                                        widthDefaultOption={false}
                                        options={rotationOption}
                                        value={selectRotationOption}
                                        onChange={(selectedOption: Option) => {
                                            setSelectRotationOption(selectedOption);
                                        }}
                                    />
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    
                                    <input 
                                        ref={inputChangeImageRef}
                                        type="file" 
                                        accept="image/*" 
                                        hidden
                                        onChange={(e) => {
                                            if(e.target.files && e.target.files.length > 0) {
                                                const file: File = e.target.files[0];
                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    const base64Result = reader.result?.toString();
                                                    setSelectedPicture(base64Result);
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />

                                    <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto'}}>
                                        <button
                                            className="btn btn-info btn-block"
                                            disabled={isLoading}
                                            onClick={ () => inputChangeImageRef.current?.click() }
                                        >
                                            { t('Change Image') }
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <button
                        className="btn btn-cactus"
                        disabled={isLoadingButton}
                        onClick={ async () => {
                            if(!selectedPicture) return;
                            const formData = new FormData();
                            formData.append('image', selectedPicture);
                            formData.append('rotation', selectRotationOption.value ?? '0');
                            formData.append('type', 'change_image');
                            formData.append('dataContentType', 'application/json');
                            try {
                                setisLoadingButton(true);
                                await _einkService.sendChangeImageCommand(einkID, formData);
                            }
                            catch (error) {}
                            finally {
                                setisLoadingButton(false);
                            }
                        }}
                    >
                        { t('Send') }
                    </button>
                </div>
            </>
        );
    }

    const _renderRightBody = () => {
        return(
            <div>
                <div className="card">
                    <div className="p-3 playlist-media-list">
                        <p>{t('Playlist')}</p>
                        <CustomSelect
                            options={[]}
                            value={null}
                            onChange={(selectedOption: Option) => {}}
                        />
                    </div>

                </div>
            </div>
        );
    }

    const _renderBody = () => {
        return(
            <div className="mt-3">
                <div className="row">
                    <div className="col-12 col-md-8">
                        {_renderLeftBody()}
                    </div>
                    <div className="col-12 col-md-4">
                        {_renderRightBody()}
                    </div>
                </div>

            </div>
        );
    }

    return(
        <DashboardWrapper>
            <div className="row">
                <div className="col-6">
                    <DashboardTitle title={t('Electronic Ink')} isLoading={isLoading}  />
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex mt-1">
                        <button
                            className="btn btn-info ml-auto"
                            disabled={isLoadingButton}
                            // onClick={ () => _handleCreateScreen() }
                        >
                            { t('Receive') }
                        </button>
                    </div>
                </div>
                <div className="col-12">
                    {
                        !isLoading && _renderBody()
                    }
                </div>
            </div>
        </DashboardWrapper>
    );

};

export default React.memo(EInkDetail);
