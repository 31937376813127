import React from 'react';
import { Link } from 'react-router-dom';
import EInk from '../../models/EInk';

interface EInkItemProps {
   eink: EInk;
};

const EInkItem: React.FC<EInkItemProps> = (props: EInkItemProps) => {
   return(
      <Link to={`/eink/${props.eink.id}`} className="screen-item__name" >
         <div className="card mb-3">
            <div className="card-body screen-item">
               <div>
                  <i className="iconsminds-monitor"></i>
                  <span className="ml-2">{ props.eink.name }</span>
               </div>
            </div>
         </div>
      </Link>
   );
}

export default React.memo(EInkItem);
