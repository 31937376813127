import React from 'react';
import { useTranslation } from 'react-i18next';
import { isVideo } from '../../models/Media';
import { IPreviewProps } from 'react-dropzone-uploader';
import ReactPlayer from 'react-player';
import iziToast from 'izitoast';

const DropzoneItem = (props: IPreviewProps) => {

    const [t] = useTranslation();

    const { name, percent, status, type, previewUrl } = props.meta;
    const { file, cancel, remove, xhr } = props.fileWithMeta;

    let resourceComponent: any = (<img className="dropzone-item__img" src={previewUrl} alt={name} title={name} />);
    if(isVideo(type)) {
        resourceComponent = <div className="dropzone-item__img"></div>;
        if(status === "done") {
            let url = URL.createObjectURL(file);
            resourceComponent = (
                <div className="dropzone-item__img">
                    <ReactPlayer url={url} controls width='100%' height='100%' />
                </div>
            );
        }
    }

    let progressClassStr: string = "dropzone-item__progress";
    if(status === "uploading") progressClassStr += " dropzone-item__progress--active";

    let errorComponent = null;
    if(status.startsWith("rejected_") || status.startsWith("error_") || status.startsWith("exception_")) {
        errorComponent = (
            <div className="dropzone-item__error">
                <p className="font-weight-bold ellipsize w100">{name}</p>
                <p className="m-0">{t('exception_upload_file')}</p>
            </div>
        );

        if(xhr) {
            xhr.onreadystatechange = function() {
                if (xhr.readyState === XMLHttpRequest.DONE) {

                    let responseText: string = xhr.responseText ?? "";
                    if(responseText.length == 0) t('exception_upload_file');
                    else responseText = JSON.parse(responseText).message ?? t('exception_upload_file');
            
                    iziToast.error({
                        title: '',
                        message: responseText,
                        position: 'bottomRight',
                        color: 'cactus--red'
                    });

                }
            }
        }
    }

    return (
        <div className="dropzone-item">

            {resourceComponent}
            
            <div className={progressClassStr}>
                <progress className="" max="100" value={Math.round(percent)}></progress>
                <button 
                    className="btn btn-cactus btn-cactus--active mt-1"
                    onClick={() => {
                        cancel();
                        remove();
                    }}
                >
                    {t('Cancel')}
                </button>
            </div>

            {errorComponent}

        </div>
    );

}

export default React.memo(DropzoneItem);
