export enum Environment {
    develop     = "http://localhost",
    production  = "https://api-lite.cactusds.com",
    staging     = "https://api-lite.cactusds.com:88"
};

export enum EnvironmentTimeService {
    develop     = "http://localhost:3400",
    production  = "https://time-service.cactusds.com",
    staging     = "https://time-service.cactusds.com"
};
