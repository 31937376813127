import { AxiosResponse } from "axios";
import ActionType from "./Action";
import * as actionTypes from './types';
import EInkService from "../services/use_case/eink/EInkService";
import EInkResponse from "../services/use_case/eink/EInkResponse";
import EInk from "../models/EInk";
import CancelRequestError from "../services/CancelRequestError";

let einkService: EInkService;

export const einkFetch = (page: number = 1, search: string = "") => {
    
   return async (dispatch: any, getState: any) => {
       
       dispatch( einkCancelRequest() );
       if(page === 1) dispatch( einkChangeValue('isLoading', true) );
       
       try {
           let response: AxiosResponse<EInkResponse> = await einkService.fetch(page, search);
           let einkResponse: EInkResponse = response.data;

           if(page === 1) dispatch( einkFetchResponse(einkResponse) );
           else dispatch( einkFetchNextResponse(einkResponse) );
       }
       catch(error) {
           if( !(error instanceof CancelRequestError) ) dispatch( einkChangeValue('isLoading', false) );
       }

   };

}

export const einkFetchResponse = (payload: EInkResponse): ActionType<EInkResponse> => {
   return {
       type: actionTypes.EINK_FETCH,
       payload,
   }
}

export const einkFetchNextResponse = (payload: EInkResponse): ActionType<EInkResponse> => {
   return {
       type: actionTypes.EINK_FETCH_NEXT,
       payload,
   }
}

export const einkCancelRequest = () => {

   return async () => {
       einkService?.cancelRequest();
       einkService = new EInkService();
   };

}

export const einkUpdate = (id: string, eink: EInk | FormData) => {

   return async (dispatch: any, getState: any) => {
   
       try {
           await new EInkService().update(id, eink);
       }
       catch(error) {
           
       }

   };

}

export const einkChangeValue = (key: string, value: any): ActionType<any> => {
   return {
       type: actionTypes.EINK_CHANGE_VALUE,
       payload: {
           key, 
           value
       },
   }
}