import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Media, { MediaSize, isImage, isVideo, getSecondsFormattedIn, getDimensionFormatted, getThumbnail } from '../../models/Media';
import ReactPlayer from "react-player";
import { useForm } from "react-hook-form";
import { CactusLabelInput } from "../../components";
import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import MediaService from "../../services/use_case/media/MediaService";
import { useDispatch } from 'react-redux';
import * as actionCreator from "../../actions";
import MediaThumbnail from '../../models/MediaThumbnail';
import DetailLibraryInfo from '../DetailLibraryInfo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';


export type DetailLibraryInputs = {
    name: string;
    description: string;
}

interface DetailLibraryProps {
    media: Media | null;
    disableOnClose?: (value: boolean) => void;
};

const DetailLibrary: React.FC<DetailLibraryProps> = (props: DetailLibraryProps) => {

    const [t] = useTranslation();

    const [navLink, setNavLink] = useState<number>(0);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [media, setMedia] = useState<Media | null>(props.media);
    const { handleSubmit, register, errors, reset } = useForm<DetailLibraryInputs>();

    const dispatch = useDispatch();
    const textValidator = register(useFormValidatorText());

    useEffect(() => {
        reset(media ?? {});
    }, [navLink]);

    useEffect(() => {
        props.disableOnClose?.(isUpdate);
    }, [isUpdate]);

    const _handleOnSubmit = async (data: DetailLibraryInputs) => {
        try {
            setIsUpdate(true);
            let updatedData = {
                name: data.name,
                description: data.description,
            }
            await new MediaService().update(media!.id, updatedData);
            let newMedia: Media = {
                ...media!,
                ...updatedData
            }
            setMedia(newMedia);
            dispatch(actionCreator.mediaUpdate(newMedia));
            setIsUpdate(false);
        }
        catch(error) {
            setIsUpdate(false);
        }
    }

    const _renderDuration = () => {

        if(!isVideo(media?.mime_type)) return null;

        return(
            <>
                <div className="col-6 text-right font-weight-bold">{t('mediaitem_duration')}:</div>
                <div className="col-6">{getSecondsFormattedIn(media?.file_duration ?? 0)}</div>
            </>
        )

    }

    const _renderDimension = () => {

        let dimensionText: string | null = getDimensionFormatted(media);

        return(
            <>
                <div className="col-6 text-right font-weight-bold">{t('mediaitem_dimension')}:</div>
                <div className="col-6">{dimensionText}</div>
            </>
        );

    }

    const _renderThumbnailsInfo = () => {
        let dimensionText: string | null;
        let elements = [];

        if(media && media.thumbnails.length > 0){
            elements = media.thumbnails.map((thumbnail: MediaThumbnail) => {
                dimensionText = `${thumbnail.width} x ${thumbnail.height}`;
                return(
                    <DetailLibraryInfo key={thumbnail.id} item = {thumbnail}></DetailLibraryInfo>
                )
            });

            return(
                <>
                    <h6 className='m-3 pl-1 font-weight-bold'>{t('mediaitem_thumbnails')}</h6>
                    {elements}
                </>
            );
        }

        return null;
    }

    const _renderNavBody = () => {

        switch(navLink) {
            case 0:
                return(
                    <>
                        <h6 className='m-3 pl-1 font-weight-bold'>{t('mediaitem_original')}</h6>
                        <DetailLibraryInfo key={media?.id} item = {media} withDuration={true}></DetailLibraryInfo>
                        
                        {_renderThumbnailsInfo()}
                    </>
                );
            case 1:
                return(
                    <form className="m-3" onSubmit={handleSubmit(_handleOnSubmit)}>
                        
                        <CactusLabelInput
                            labelTitle={t('Name')}
                            inputAttributes={{
                                id: "name",
                                name: "name",
                                type:  "text",
                                ref: textValidator,
                            }}
                            errorMessage={errors.name ? errors.name.message : null}
                        />

                        <CactusLabelInput
                            labelTitle={t('Description')}
                            inputAttributes={{
                                id: "description",
                                name: "description",
                                type:  "text",
                            }}
                            errorMessage={errors.description ? errors.description.message : null}
                        />

                        <button 
                            type="submit"
                            className="btn btn-cactus mt-2"
                            disabled={isUpdate}
                        >
                            {t('Update Model', { model: t('Media') })}
                        </button>

                    </form>
                );
        }

        return null;

    }

    return(
        <div className="row align-items-center">
            
            <div className="col-12 col-md-8">

                <div className="p-4">
                    {isImage(media?.mime_type) ? <img className="img-fluid w-100" src={getThumbnail(media, "system_full_hd")} alt={media?.name} /> : null}
                    {isVideo(media?.mime_type) ? <ReactPlayer url={getThumbnail(media, "system_full_hd")} width='100%' controls /> : null}
                </div>

            </div>

            <div className="col-12 col-md-4 px-0" style={{ borderLeft: "1px solid #0a0a0a" }}>
                
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a 
                            className={`nav-link ${navLink === 0 ? "active" : null}`}
                            href="!#"
                            onClick={(e) => {
                                e.preventDefault();
                                if(!isUpdate) setNavLink(0);
                            }}
                        >
                            {t('Details')}
                        </a>
                    </li>
                    <li className="nav-item">
                        <a 
                            className={`nav-link ${navLink === 1 ? "active" : null}`}
                            href="!#"
                            onClick={(e) => {
                                e.preventDefault();
                                if(!isUpdate) setNavLink(1);
                            }}
                        >
                            {t('Update')}
                        </a>
                    </li>
                </ul>

                {_renderNavBody()}

            </div>

        </div>
    );

};

export default React.memo(DetailLibrary);
