import BaseService from "../../BaseService";
import { AxiosResponse } from "axios";
import EInk from "../../../models/EInk";
import ApiResponse from "../../ApiResponse";

export default class EInkService extends BaseService {

   fetch(page: number = 1, search: string = ""): Promise<AxiosResponse<any>> {
      let uri: string = `/eink?page=${page}`;

      if(search !== "") uri += `&q=${search}`;

      return this.axios.get(uri, this.getCancelTokenStructure());
   }

   store(formData: FormData): Promise<AxiosResponse<ApiResponse<EInk>>> {
      return this.axios.post(`/eink`, formData);
   }

   show(id: string): Promise<AxiosResponse<ApiResponse<EInk>>> {
      return this.axios.get(`/eink/${id}`, this.getCancelTokenStructure() );
   }

   update(id: string, eink: EInk | FormData): Promise<AxiosResponse<ApiResponse<EInk>>> {
      return this.axios.put(`/eink/${id}`, eink);
   }

   delete(id: string): Promise<AxiosResponse<ApiResponse<EInk>>> {
      return this.axios.delete(`/eink/${id}`);
   }

   sendChangeImageCommand(id: string, formData: FormData): Promise<AxiosResponse<ApiResponse<void>>> {
      return this.axios.post(`eink/${id}/command`, formData);
   }

}
