import React from 'react';
import { useTranslation } from 'react-i18next';
import Config from '../../models/Config';
import { getMediaDuration } from "../../models/Media";

interface ConfigItemProps {
    config: Config
};

const ConfigItem: React.FC<ConfigItemProps> = (props: ConfigItemProps) => {

    const [t] = useTranslation();

    const _renderValue = () => {

        if( props.config.key === "DEFAULT_MEDIA_DURATION" ) {
            return `${ Number(props.config.value) ?? 0 }s`;
        }

        return props.config.value;

    }

    return(
        <div className="card mt-3">
            <div className="card-body config">
                <div className="config__key">{ props.config.key }</div>
                <div className="config__value">{ _renderValue() }</div>
                <div className="config__badge">
                    {
                        ( props.config.editable === 1)?
                        <span className="badge badge-pill badge-success">{t('Editable')}</span>:
                        <span className="badge badge-pill badge-secondary">{t('Not Editable')}</span>
                            
                    }
                </div>
            </div>
        </div>
    );

};

export default React.memo(ConfigItem);
