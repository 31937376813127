import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import Option from "../../models/Option";
import { useForm } from 'react-hook-form';
import ScreenGroup from '../../models/ScreenGroup';
import { ScreenGroupInputs } from '../../containers/ScreenGroup/ScreenGroup';
import ScreenGroupService from '../../services/use_case/screengroup/ScreenGroupService';
import { useDispatch } from 'react-redux';


interface ScreenGroupItemProps {
    screenGroup: ScreenGroup;
    defaultOption: Option[];
    openModal: (screenGroup: ScreenGroup) => void;
};

const ScreenGroupItem: React.FC<ScreenGroupItemProps> = (props: ScreenGroupItemProps) => {

    let { id: screenGroupId } = useParams<{id:string}>() 
    const [t] = useTranslation();
    const [screenGroup, setscreenGroup] = useState<ScreenGroup>()

    const dispatch = useDispatch();

    let history = useHistory()

    const { register } = useForm<ScreenGroupInputs>({
        defaultValues: {
            ...screenGroup
        }
    });    

    return(
        <div className="card mb-3">
            <div className="card-body screen-item">
                <Link to={`screengroups/${props.screenGroup.id}`} className="screen-item__name" >
                    <i className="iconsminds-monitor"></i>
                    <span className="ml-2">{ props.screenGroup.name }</span>
                </Link>
                <div className="screen-item__options">
                    <a className="screen-item__name" onClick={() => props.openModal(props.screenGroup)}>
                        <i className="iconsminds-gear pointer"></i>
                    </a>
                    
                </div>
            </div>
        </div>
    );

};

export default React.memo(ScreenGroupItem);
