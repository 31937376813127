import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Media, { getSecondsFormattedIn } from '../../models/Media';
import MediaThumbnail from '../../models/MediaThumbnail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';


interface DetailLibraryInfoProps {
    item: Media | MediaThumbnail | null;
    withDuration?: boolean;
};

const DetailLibraryInfo: React.FC<DetailLibraryInfoProps> = (props: DetailLibraryInfoProps) => {

    const [t] = useTranslation();
    if(!props.item) return null;
    const { full_path, size_str, width, height, mime_type, file_duration } = props.item;
    const dimensionText = `${width} x ${height}`;
    const withDuration = props.withDuration ?? false;

    const _renderDurationFields = () => {
        if(withDuration && mime_type.startsWith("video/")){
            return(
                <>
                    <div className="col-6 text-right font-weight-bold">{t('mediaitem_duration')}:</div>
                    <div className="col-6">{getSecondsFormattedIn(file_duration ?? 0)}</div>
                </>
            )
        }
        return null;
    }

    return(
        <div className="m-3 p-3 bg-modal-box">
            <a href={full_path} target="_blank">
                <div className="row align-items-center">
                    <div className="col-10">
                        <div className="row">
                            <div className="col-6 text-right font-weight-bold">{t('mediaitem_size')}:</div>
                            <div className="col-6">{size_str}</div>

                            {_renderDurationFields()}

                            <div className="col-6 text-right font-weight-bold mt-1">{t('mediaitem_dimension')}:</div>
                            <div className="col-6 mt-1">{dimensionText}</div>
                        </div>
                    </div>
                    <div className="col-2">
                        <FontAwesomeIcon icon={faEye} size="1x" />
                    </div>
                </div>
            </a>
        </div>
    );

};

export default React.memo(DetailLibraryInfo);
