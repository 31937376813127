import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { CactusLabelInput, CactusModal, DashboardTitle, DashboardWrapper, SearchDebounce, CustomSelect, ModalDeleteConfirm } from '../../components';
import Screen, { getScreenOption } from '../../models/Screen';
import * as actionCreator from "../../actions";
import ScheduleModel from "../../models/Schedule";
import ScreenService from "../../services/use_case/screen/ScreenService";

import ApiResponse from '../../services/ApiResponse';
import { AxiosResponse } from 'axios';

import Option from "../../models/Option";
import PlaylistService from '../../services/use_case/playlist/PlaylistService';
import Playlist, { getOption } from '../../models/Playlist';

import useFormValidatorText from '../../hooks/validators/use-form-validator-text';

import { Calendar, momentLocalizer, Event } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import CustomCalendarToolBar from '../../components/CustomCalendarToolBar';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import ScheduleService from '../../services/use_case/schedule/ScheduleService';
import { screen } from '@testing-library/react';

let lang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? "en";

moment.locale(lang); // Cambia los nombres a español


let screenService: ScreenService;

interface ScheduleEvent extends Event {
    id: string;
    linked_id: string;
    priority: number;
}
export interface ScheduleInputs {
    name: string;
    devide_id: string;
    linked_id: string;
    start_date: Date;
    end_date: Date;
    priority: number;
}

interface ScheduleProps {};

const Schedule: React.FC<ScheduleProps> = (props: ScheduleProps) => {

    lang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? "en";

    const [t] = useTranslation();
    const [search, setSearch] = useState<string>("");

    const [screenSelected, setScreenSelected] = useState<Screen|null>(null);
    const [schedules, setSchedules] = useState<ScheduleModel[]>([]);
    const [scheduleDate, setScheduleDate] = useState(new Date());
    
    const [scheduleForm, setScheduleForm] = useState(false);
    const [updatingSchedule, setUpdatingSchedule] = useState(false);
    const [deletingScheduleForm, setDeletingScheduleForm] = useState(false);

    const [selectedScheduleEvent, setSelectedScheduleEvent] = useState<ScheduleEvent | null>(null);

    const [defaultPlaylistOption, setPlaylistDefaultOption] = useState<Option[]>([]);
    const [playlistValue, setPlaylistValue] = useState<Option | null>(null);

    const [defaultScreenOption, setScreenDefaultOption] = useState<Option[]>([]);

    const [isLoadingCreateSchedule, setisLoadingCreateSchedule] = useState(false);
    const { handleSubmit, register, errors } = useForm<ScheduleInputs>();
    const textValidator = register(useFormValidatorText());
    const dispatch = useDispatch();

    const screenReducer = useSelector( (state: any) => state.screenReducer);
    const pagination: any = screenReducer?.data;
    const data: any = pagination?.data ?? [];
    const isLoading = screenReducer?.isLoading;

    let playlistService: PlaylistService;

    moment.locale(lang);
    const localizer = momentLocalizer(moment)

    registerLocale('es', es)

    useEffect( () => {
        dispatch( actionCreator.screenFetch() );
        _fetchDefaultOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if(!screenSelected) {
            setSchedules([]);
            return;
        }

        fetchscreenSchedule(screenSelected.id);

    }, [screenSelected]);

    useEffect(() => {
        if(!scheduleForm) {
            setScreenDefaultOption([])
            //setPlaylistDefaultOption([])
            return;
        }
        //_fetchDefaultOptions();
    }, [scheduleForm])

    const _fetchDefaultOptions = async () => {
        try {
            playlistService = new PlaylistService();
            let response: any = await playlistService.fetch();
            let playlists: Playlist[] = response.data?.data?.data ?? [];
    
            let options = playlists.map((playlist: Playlist) => getOption(playlist) );
    
            setPlaylistDefaultOption(options);
        }
        catch(error) {}
    }

    const _fetchScreens = (page?: number) => {
        dispatch( actionCreator.screenCancelRequest() );
        dispatch( actionCreator.screenFetch(page, search) );
    }

    const fetchscreenSchedule = async (id: string) => {
        screenService?.cancelRequest();
        screenService = new ScreenService();

        try {
            const response = await screenService.schedulesByScreenId(id);
            const screenWithSchedule = response.data?.data?.data ?? [];

            setSchedules(screenWithSchedule)
        }
        catch(error) {}
    }

    const _handleCreateSchedule = () => {
        setSelectedScheduleEvent(null)
        setScheduleForm(true)
        setPlaylistValue(null)
    }

    const _handleUpdateSchedule = (scheduleEvent: ScheduleEvent) => {

        let playlist = defaultPlaylistOption.find((element) => element.value == scheduleEvent.linked_id);
        
        if(playlist) setPlaylistValue(playlist)

        setScheduleForm(true)
        setSelectedScheduleEvent(scheduleEvent);
    }

    const _renderScreens = () => {

        if(isLoading) return null;

        let screens: Screen[] = data?.map((screen: Screen, index: number) => {

            let screenClassActive = `${screen.id === screenSelected?.id ? 'screen-item--active' : null}`;

            return(
                <div key={screen.id} className="card mb-3" onClick={() => setScreenSelected(screen)}>
                    <div className={`card-body pointer screen-item ${screenClassActive}`}>
                        <i className="iconsminds-monitor"></i>
                        <span className="ml-2">{ screen.name }</span>
                    </div>
                </div>
            );

        }) ?? [];

        if(screens.length === 0) {
            return(
                <div>
                    {t('No Result')}
                </div>
            )
        }

        return(
            <>
                {screens}
            </>
        );

    }

    const _handleOnSubmit = async(json: any) => {

        let screenOptions = null;
        if(screenSelected){
            screenOptions = getScreenOption(screenSelected);
        }

        json["device_id"] = screenOptions?.value ?? "";
        json["device_type"] = screenOptions?.type ?? "";
        json["linked_id"] = playlistValue?.value ?? "";
        json["linked_type"] = playlistValue?.type ?? "";

        json["start_date"] = new Date(json.start_date).toUTCString(); 
        json["end_date"] = new Date(json.end_date).toUTCString();

        try {
            if(selectedScheduleEvent?.id && updatingSchedule){
                let response:AxiosResponse<ApiResponse<ScheduleModel>> = await new ScheduleService().update(selectedScheduleEvent?.id, json);
                const scheduleResponse = response.data.data;
                setSchedules((prevState: ScheduleModel[]) => {
                    prevState.forEach((schedule: ScheduleModel) => {
                        if (schedule.id == selectedScheduleEvent.id && scheduleResponse) {
                            schedule.name = scheduleResponse.name
                            schedule.start_date = scheduleResponse.start_date
                            schedule.end_date = scheduleResponse.end_date
                            schedule.linked_id = scheduleResponse.linked_id
                            schedule.priority = scheduleResponse.priority
                        }
                    });
                    return prevState
                })
            } else {
                let response:AxiosResponse<ApiResponse<ScheduleModel>> = await new ScheduleService().store(json);
                const scheduleResponse = response.data.data;
                setSchedules((schedules: ScheduleModel[]) => schedules.concat(scheduleResponse ?? []));
            }
        }
        catch (err){}

        setScheduleForm(false);
    }

    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className={`btn btn-cactus mt-2 ml-auto `}
                //${isLoadingCreateScreen && 'btn-cactus--loading'}
                //disabled={isLoadingCreateScreen ?? false}
            >
                {t('Create_schedule')}
            </button>
        );
    }

    const _renderBtnDelete = () => {
        return (
            <button 
                type="button"
                onClick={ () => setDeletingScheduleForm(true) }
                className={`btn btn-danger mt-2 `}
                //${isLoadingCreateScreen && 'btn-cactus--loading'}
                //disabled={isLoadingCreateScreen ?? false}
            >
                {t('Delete_schedule')}
            </button>
        );
    }

    const _renderBtnUpdate = () => {
        return (
            <button 
                type="submit"
                onClick={() => setUpdatingSchedule(true)}
                className={`btn btn-cactus mt-2 ml-auto`}
                //${isLoadingCreateScreen && 'btn-cactus--loading'}
                //disabled={isLoadingCreateScreen ?? false}
            >
                {t('Update_schedule')}
            </button>
        );
    }

    const _deleteSchedule = async() => {

        if(!selectedScheduleEvent) return;

        try {
            let _scheduleService: ScheduleService = new ScheduleService();
            await _scheduleService.delete(selectedScheduleEvent.id)
            setDeletingScheduleForm(false)
            setScheduleForm(false)
            setSchedules((prevState: ScheduleModel[]) => {
                return prevState.filter((state: ScheduleModel) => state.id != selectedScheduleEvent.id);
            })
        } catch (error) {

        }
    }

    const _returnStartDate = (): string => {
        if(!selectedScheduleEvent?.start) return "";
        
        return moment(selectedScheduleEvent?.start).format("YYYY-MM-DDTHH:mm");
    }

    const _returnEndDate = (): string => {
        if(!selectedScheduleEvent?.end) return "";
        
        return moment(selectedScheduleEvent?.end).format("YYYY-MM-DDTHH:mm");
    }

    const _renderCreateScheduleForm = () => {
        return (
            <div className="col-12">
                <form onSubmit={handleSubmit(_handleOnSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            <CactusLabelInput
                                labelTitle={t('Schedule_name')}
                                inputAttributes={{
                                    id: "name",
                                    name: "name",
                                    type:  "name",
                                    defaultValue: selectedScheduleEvent?.title,
                                    placeholder: t('Name'),
                                    autoComplete: "name",
                                    ref: textValidator,
                                }}
                                errorMessage={errors.name ? errors.name.message : null}
                            />
                        </div>
                        
                        <div className="col-12">
                            <label htmlFor="">{t('Playlists')}</label>
                            <CustomSelect
                                options={defaultPlaylistOption}
                                value={playlistValue}
                                onChange={(selectedOption: Option) => {
                                    setPlaylistValue(selectedOption);
                                }}
                            />
                        </div>

                        <div className="col-12">
                            <label htmlFor="">{t('Schedule_startDate')}</label>
                            <input type="datetime-local" name="start_date" id="" defaultValue={_returnStartDate()} ref={register}/>
                        </div>

                        <div className="col-12">
                            <label htmlFor="">{t('Schedule_endDate')}</label>
                            <input type="datetime-local" name="end_date" id="" defaultValue={_returnEndDate()} ref={register}/>
                        </div>

                        <div className="col-12">
                            <label htmlFor="">Prioridad: </label>
                            <input type="number" min={0} max={2} name="priority" defaultValue={selectedScheduleEvent?.priority} id="" ref={register}/>
                        </div>
                    </div>
                    <div className="row">
                        

                        { selectedScheduleEvent?.id ? (
                                <>
                                    <div className="col-6 d-flex">
                                        { _renderBtnDelete() }
                                    </div>
                                    <div className="col-6 d-flex">
                                        { _renderBtnUpdate() }
                                    </div>
                                </>
                            ) : (
                                <div className="col-12 d-flex">
                                    { _renderBtnSubmit() }
                                </div>
                            )
                        }
                    </div>
                </form>
            </div>
        )
    }

    const _handleDateChange = (newDate:Date) => {
        setScheduleDate(newDate);
    };

    const _showSchedule = () => {

        if(!screenSelected || !schedules) return null;

        const events: ScheduleEvent[] = schedules.map((schedule: ScheduleModel) => _getEvent(schedule));

        return(
            <>
                <div className='schedule__toolbar'>
                    <button 
                        className='btn btn-cactus'
                        disabled={isLoading}
                        onClick={ () => _handleCreateSchedule() }
                    >
                        {t('New_schedule')}
                    </button>
                </div>
                <Calendar
                    localizer={localizer}
                    events={events}
                    date={scheduleDate}
                    drilldownView={null}
                    startAccessor="start"
                    endAccessor="end"
                    showMultiDayTimes={true}
                    views={['week', 'day']} 
                    defaultView='week'
                    dayLayoutAlgorithm="no-overlap"
                    onSelectEvent={_handleUpdateSchedule}
                    components={{toolbar: CustomCalendarToolBar}}
                    onNavigate={_handleDateChange}
                />
            </>
        );

    }

    const _getEvent = (schedule: ScheduleModel): ScheduleEvent => {
        let event: ScheduleEvent = {
            id: schedule.id,
            title: schedule.name,
            linked_id: schedule.linked_id,
            priority: schedule.priority,
            start: moment(schedule.start_date).toDate(),
        }

        if(schedule.end_date) {
            event['end'] = moment(schedule.end_date).toDate();
        } else {
            event['end'] = moment('9999-12-31T00:00:00-05:00').toDate();
        }

        return event;
    }

    return(
        <DashboardWrapper>
            <DashboardTitle title={t('Schedule')} isLoading={isLoading}/>

            <div className="schedule">
                <div className="schedule__filter">

                    <SearchDebounce
                        placeholder={`${t('Name')}`}
                        borderColor="transparent"
                        onChange={(text: string) => {
                            setSearch(text);
                            setScreenSelected(null);
                        }}
                        debounceFinish={(text: string) => _fetchScreens(1)}
                    />

                </div>

                <div className="schedule__screens">
                    {_renderScreens()}
                </div>

                <div className='schedule__content'>
                    {_showSchedule()}
                </div>

                <CactusModal
                    show={scheduleForm}
                    title={ t('New_schedule') }
                    onClose={() => {
                        setScheduleForm(false)
                        //setSelectedScheduleEvent(null)
                    }}
                    minWidth="500px"
                >
                    <div className="row">
                    {
                        _renderCreateScheduleForm()
                    }
                    </div>
                </CactusModal>

                <ModalDeleteConfirm 
                    show={deletingScheduleForm}
                    title={t('Delete_schedule')}
                    onCloseFunction={setDeletingScheduleForm}
                    onCloseFunctionParameter={false}
                    entityTranslation={t('schedule')}
                    buttonLoading={isLoadingCreateSchedule}
                    onClickFunctionButton={_deleteSchedule}
                    buttonText={t('Delete')}
                />
            </div>

        </DashboardWrapper>
    );

};

export default React.memo(Schedule);
