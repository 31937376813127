import React from 'react';
import { Link } from 'react-router-dom';
import Playlist from '../../models/Playlist';

interface PlaylistItemProps {
    playlist: Playlist;
};

const PlaylistItem: React.FC<PlaylistItemProps> = (props: PlaylistItemProps) => {

    return(
        <Link to={`playlists/${props.playlist.id}`} className="playlist-item__name" >
            <div className="card mb-3">
                <div className="card-body playlist-item">
                    <div>
                        <i className="iconsminds-check"></i>
                        <span className="ml-2">{ props.playlist.name }</span>
                    </div>
                </div>
            </div>
        </Link>
    );

};

export default React.memo(PlaylistItem);
