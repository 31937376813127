import BaseService from "../../BaseService";
import { AxiosResponse } from "axios";
import Schedule from "../../../models/Schedule";
import ApiResponse from "../../ApiResponse";

export default class ScheduleService extends BaseService {

    store(formData: FormData): Promise<AxiosResponse<ApiResponse<Schedule>>> {
        return this.axios.post(`/schedules`, formData);
    }

    update(id: string, formData: FormData): Promise<AxiosResponse<ApiResponse<Schedule>>> {
        return this.axios.put(`/schedules/${id}`, formData);
    }

    delete(id: string): Promise<AxiosResponse<ApiResponse<Schedule>>> {
        return this.axios.delete(`/schedules/${id}`);
    }

}
