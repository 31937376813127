import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import nprogress from 'nprogress';
import ReactGA from "react-ga";

interface PageWrapperProps {
    children: React.ReactNode;
}

const PageWrapper = (props: PageWrapperProps) => {
    
    let location = useLocation();
    
    if(process.env.NODE_ENV !== 'development') nprogress.start();

    useEffect(() => {
        if(process.env.NODE_ENV !== 'development') {
            ReactGA.pageview(location.pathname);
            nprogress.done();
        }
    }, [location]);

    return(
        <>{props.children}</>
    );

};

export default React.memo(PageWrapper);
