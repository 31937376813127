import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    DashboardWrapper,
    DashboardTitle,
    SearchDebounce,
    EInkItem,
} from '../../components';
import EInkModel from '../../models/EInk';
import * as actionCreator from "../../actions";
import ScreenItemSkeleton from '../../components/ScreenItem/ScreenItemSkeleton';
import InfiniteScroll from 'react-infinite-scroller';
import { IconLoader } from '../../assets/icons/IconLoader';

interface EInkProps {};

const EInk: React.FC<EInkProps> = (props: EInkProps) => {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const [search, setSearch] = useState<string>('');

    const einkResponse = useSelector( (store: any) => store.einkReducer );
    const isLoading = einkResponse?.isLoading
    const pagination: any = einkResponse?.data;
    const data: any = pagination?.data ?? [];

    useEffect(() => {
        _fetchInks(1);

        return () => {
            dispatch( actionCreator.einkCancelRequest() );
        }

    }, []);

    const _fetchInks = (page?: number) => {
        dispatch( actionCreator.einkCancelRequest() );
        dispatch( actionCreator.einkFetch(page, search) );
    }

    const _renderSkeletonLoading = () => {
        return Array.from({length: 15}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="mb-3">
                    <ScreenItemSkeleton />
                </div>
            );
        });
    }

    const _renderInks = () => {

        if(isLoading) {
            return _renderSkeletonLoading();
        }

        let einks: EInkModel[] = data?.map((eink: EInkModel, index: number) => {
            return(
                <EInkItem
                    key={index}
                    eink={eink}
                />
            )
        }) ?? [];

        if(einks.length === 0) {
            return(
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
                        {t('No Result')}
                    </div>
                </div>
            )
        }

        return (
            <InfiniteScroll
                pageStart={pagination?.from}
                loadMore={() => {
                    _fetchInks(pagination?.current_page ? pagination?.current_page + 1 : 1)
                }}
                hasMore={pagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
            >
                {einks}
            </InfiniteScroll>
        );
    }

    return(
        <DashboardWrapper>
            <div className="row">
                
                <div className="col-12 col-md-6">
                    
                    <DashboardTitle
                        title={t('Electronic Ink')}
                        isLoading={isLoading}
                        withRefreshIcon={!isLoading}
                        refreshIconCallBack={() => {
                            _fetchInks(1);
                        }}
                    />

                    <SearchDebounce
                        placeholder={`${t('Name')}`}
                        borderColor="transparent"
                        onChange={(text: string) => setSearch(text)}
                        debounceFinish={(text: string) => _fetchInks(1)}
                    />

                </div>

                <div className="col-12 mt-3">
                    {_renderInks()}
                </div>

            </div>
        </DashboardWrapper>
    );

};

export default React.memo(EInk);
