import React from 'react';
import { ILayoutProps } from 'react-dropzone-uploader';

const DropzoneLayout = (props: ILayoutProps) => {

   const { input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } } = props;

   return (
       <div {...dropzoneProps}>
           {previews}

           {files.length < maxFiles && input}

           {files.length > 0 && submitButton}
       </div>
   );
}

export default React.memo(DropzoneLayout);
