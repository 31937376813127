import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import { getSecondsFormattedIn } from "../../models/Media";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import ApiResponse from '../../services/ApiResponse';
import { PlaylistInputs } from '../Playlist/Playlist';
import * as actionCreator from "../../actions";

import { 
    CactusLabelInput,
    DashboardTitle, 
    DashboardWrapper, 
    FilterButton, 
    ModalDeleteConfirm,
    PlaylistDetailMediaItem,
    PlaylistDropZoneDropItem,
    PlaylistDropZoneLoading,
    PlaylistResourceItem,
    SearchDebounce,
    CactusModal,
    CreateLibrary,
    WidgetDesignerRightPanel,
} from '../../components';

import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import Playlist from '../../models/Playlist';
import ResourceItem, { setResourceDuration } from '../../models/ResourceItem';
import { RightPanel } from '..';
import { DndProvider } from 'react-dnd';
import Media, { getAllMediaFilters, ifShowMediabyFilter } from '../../models/Media';
import Widget, { getAllWidgetFilters } from '../../models/Widget';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PlaylistDropZoneEmpty from '../../components/PlaylistDropZoneEmpty';
import { PlaylistMediaItemSkeleton } from '../../components/PlaylistResourceItem/PlaylistResourceItemSkeleton';
import { IconLoader } from '../../assets/icons/IconLoader';
import InfiniteScroll from 'react-infinite-scroller';

import PlaylistService from '../../services/use_case/playlist/PlaylistService';
import ResourcePlaylistService from '../../services/use_case/resource_playlist/ResourcePlaylistService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export const PlaylistMediaItemTypes = {
    CARD: 'MediaCard'
}

interface PlaylistDetailProps {};

const PlaylistDetail: React.FC<PlaylistDetailProps> = (props: PlaylistDetailProps) => {

    const [t] = useTranslation();

    const _playlistService: PlaylistService = new PlaylistService();
    const _resourcePlayListService: ResourcePlaylistService = new ResourcePlaylistService();

    let { id: playlistId } = useParams<{id:string}>();

    const [isUpdatingForm, setIsUpdatingForm] = useState(false);
    const [deletingPlaylist, setDeletingPlaylist] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [playlist, setPlaylist] = useState<Playlist>();
    const [isLoadingPlaylistItems, setIsLoadingPlaylistItems] = useState(false);
    const [isDraggingMediaItems, setIsDraggingMediaItems] = useState(false);

    const [isDeletingMediaItem, setIsDeletingMediaItem] = useState(false);
    const [deleteResourceItemId, setDeleteResourceItemId] = useState<string | null>(null);
    const [playlistDurationSeconds, setPlaylistDurationSeconds] = useState(0);
    const [playlistDuration, setPlaylistDuration] = useState('');
    
    // media
    const [mediaSearch, setMediaSearch] = useState<string>('');
    const [mediaFilterSelected, setMediaFilterSelected] = useState<string>(getAllMediaFilters()[0]);

    // Widget
    const [widgetSearch, setWidgetSearch] = useState<string>('');
    const [widgetFilterSelected, setWidgetFilterSelected] = useState<string>(getAllWidgetFilters()[0]);

    // 
    const [resourceItems, setResourceItems] = useState<ResourceItem[]>([]);

    const [creatingLibrary, setCreatingLibrary] = useState<boolean>(false);
    const [disableOnClose, setDisableOnClose] = useState<boolean>(false);

    const [rightPanelNavLink, setRightPanelNavLink] = useState<number>(0);

    const [isOpenWidgetDesigner, setIsOpenWidgetDesigner] = useState<boolean>(false);

    // Reducers
    const mediaReducer = useSelector( (store: any) => store.mediaReducer );
    const mediaPagination = mediaReducer.data;
    const medias = mediaPagination?.data;

    const widgetReducer = useSelector( (store: any) => store.widgetReducer );
    const widgetPagination = widgetReducer.data;
    const widgets = widgetReducer.items;
    const isWidgetLoading = widgetReducer.isLoading;

    const dispatch = useDispatch();

    let history = useHistory();

    const { handleSubmit, register, errors, reset } = useForm<PlaylistInputs>({
        defaultValues: {
            ...playlist
        }
    })
    const textValidator = register(useFormValidatorText());
    
    useEffect(() => {
        _getPlaylist(playlistId);
        
        return () => {
            _playlistService.cancelRequest();
            dispatch( actionCreator.playlistSetCurrent( null ) );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        setPlaylistDuration( getSecondsFormattedIn(playlistDurationSeconds) );
        
    }, [playlistDurationSeconds]);

    useEffect(() => {
        _fetchLibraries(1);
    }, [mediaFilterSelected]);

    useEffect(() => {
        _fetchWidgets(1);
    }, [widgetFilterSelected]);

    const _fetchLibraries = (page?: number) => {
        dispatch( actionCreator.mediaCancelRequest() );
        dispatch( actionCreator.mediaFetch(page, mediaSearch, mediaFilterSelected) );
    }

    const _fetchWidgets = (page?: number) => {
        dispatch( actionCreator.widgetCancelRequest() );
        dispatch( actionCreator.widgetFetch(page, widgetSearch, widgetFilterSelected) );
    }

    const _getPlaylistTotalTime = (playlist: Playlist) => {

        const items: ResourceItem[] = playlist.items ?? [];

        return items.reduce((acumulator, item) => {
            return acumulator + item.duration;
        }, 0);

    }
    
    const _getPlaylist = async (id:string) => {
        setIsLoading(true);

        try {

            const resourcePlayListResponse: AxiosResponse<ApiResponse<Playlist>> = await _resourcePlayListService.index(id);
            const playlist: Playlist = resourcePlayListResponse.data.data!;

            const items: ResourceItem[] = playlist.items ?? [];

            setPlaylist(playlist);
            reset(playlist);
            setResourceItems(items);

            dispatch( actionCreator.playlistSetCurrent( playlist ) );

            setPlaylistDurationSeconds(_getPlaylistTotalTime(playlist));
            setIsLoading(false);

        }
        catch(error) {
            history.replace('/playlists');
            setIsLoading(false);
        }
    }

    const _handleAddResourceToPlaylist = async (resource: Media | Widget | undefined) => {
        
        if(!resource) return;

        const playlist_id: string = playlist?.id ?? "";
        const resource_id: string = resource.id;
        let resource_type: string = "";
        
        if(resource.mime_type.startsWith("widget")) {
            resource_type = "widget";
        }
        else {
            resource_type = "media";
        }
        
        setIsLoadingPlaylistItems(true);

        try {

            const playlistStoreResourceResponse: AxiosResponse<ApiResponse<Playlist>> = await _resourcePlayListService.store(playlist_id, resource_id, resource_type);
            
            const playlist: Playlist = playlistStoreResourceResponse.data.data!;
            setPlaylist(playlist);

            const items: ResourceItem[] = playlist.items ?? [];
            setResourceItems(items);
            dispatch( actionCreator.playlistSetCurrent( playlist ) );
            setPlaylistDurationSeconds(_getPlaylistTotalTime(playlist));
            setIsLoadingPlaylistItems(false);


        }
        catch(error) {
            setIsLoadingPlaylistItems(false);
        }

    }

    const _handleOnSubmit = async(data:FormData) => {
        setIsUpdatingForm(true)
        try {
            const updateResponse:AxiosResponse<ApiResponse<Playlist>> = await _playlistService.update(playlistId, data)
            setIsUpdatingForm(false)
            _getPlaylist(playlistId)
        } catch(error) {
            setIsUpdatingForm(false)
        }
    }

    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className={`btn btn-sm btn-cactus mt-2 ${ isUpdatingForm && 'btn-cactus--loading'}`}
                disabled={isUpdatingForm ?? false}
            >
                {t('Update_playlist')}
            </button>
        );
    }

    const _renderDeleteButton = () => {
        return (
            <button 
                type="submit"
                onClick={ () => setDeletingPlaylist(true) }
                className="btn btn-sm btn-cactus--red mt-2"
                disabled={isUpdatingForm ?? false}
            >
                {t('Delete_playlist')}
            </button>
        )
    }

    const _renderScreenDetailBody = () => {

        if(isLoading) {
            return(
                <SkeletonTheme color={process.env.REACT_APP_SKELETON_COLOR} highlightColor={process.env.REACT_APP_SKELETON_HIHGLIGHTS_COLOR}>
                    <div>
                        <Skeleton className="col-12" height={150} />
                    </div>
                </SkeletonTheme>
            );
        }

        return (
            <div className="card mb-4">
                <div className="card-body">
                    <form onSubmit={handleSubmit(_handleOnSubmit)} >
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <CactusLabelInput
                                    labelTitle={t('Name')}
                                    inputAttributes={{
                                        id: "name",
                                        name: "name",
                                        type:  "text",
                                        placeholder: t('Name'),
                                        autoComplete: "name",
                                        ref: textValidator,
                                    }}
                                    errorMessage={errors.name ? errors.name.message : null}
                                />
                            </div>
                            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-end">
                                <div>{ _renderBtnSubmit() }</div>
                               
                            </div>                            
                        </div>
                    </form>
                    <div className="col-12 text-right pr-0">
                        { _renderDeleteButton() }
                    </div>
                </div>
            </div>
        )
    }

    const _deletePlaylist = async() => {
        setIsUpdatingForm(true)
        try {
            await _playlistService.delete(playlistId)
            setIsUpdatingForm(false)
            setDeletingPlaylist(false)
            history.replace('/playlists')
        } catch (error) {
            setIsUpdatingForm(false)
        }
    }

    const _changeMediaFilter = (event: any) => {
        let filter: string = event.target.name;
        setMediaFilterSelected(filter);
    }

    const _changeWidgetFilter = (event: any) => {
        let filter: string = event.target.name;
        setWidgetFilterSelected(filter);
    }

    const _renderMediaInputAndTags = () => {

        let filters: JSX.Element[] = getAllMediaFilters().map((filter: string) => (
            <FilterButton
                key={filter}
                name={filter}
                className={`btn btn-cactus btn-cactus--red`}
                active={mediaFilterSelected === filter}
                onClick={_changeMediaFilter}
            >
                {t(`filter_button_${filter}`)}
            </FilterButton>
        ));

        return(
            <div className="mt-3">
                <SearchDebounce
                    placeholder={`${t('Name')}, ${t('Description')}`}
                    onChange={(text: string) => setMediaSearch(text)}
                    debounceFinish={(text: string) => _fetchLibraries(1)}
                />
                <div className="d-flex mt-3">
                    {filters}
                </div>
            </div>
        )
    }

    const _renderWidgetInputAndTags = () => {

        let filters: JSX.Element[] = getAllWidgetFilters().map((filter: string) => (
            <FilterButton
                key={filter}
                name={filter}
                className={`btn btn-cactus btn-cactus--red`}
                active={widgetFilterSelected === filter}
                onClick={_changeWidgetFilter}
            >
                {t(`filter_button_${filter}`)}
            </FilterButton>
        ));

        return(
            <div className="mt-3">
                <SearchDebounce
                    placeholder={`${t('Name')}`}
                    onChange={(text: string) => setWidgetSearch(text)}
                    debounceFinish={(text: string) => _fetchWidgets(1)}
                />
                <div className="d-flex mt-3">
                    {filters}
                </div>
            </div>
        )

    }

    const _handleDragMediaItems = (loading:boolean) => {

        setIsDraggingMediaItems(loading)
        
    }

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {

            const dragCard = resourceItems[dragIndex];

            setResourceItems(
            update(resourceItems, {
                    $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                    ],
                }),
            )

        },
        [resourceItems],
    )

    const _handleUpdateOrder = async() => {

        // CHECK ORDER
        if(JSON.stringify(playlist?.items ?? []) === JSON.stringify(resourceItems)) return;
        
        const order: string[] = resourceItems.map( (resource: ResourceItem) => (resource.id) );
        setIsLoadingPlaylistItems(true);

        try {

            const updateResponse: AxiosResponse<ApiResponse<Playlist>> = await _resourcePlayListService.updateOrder(playlistId, order);
            
            const playlist: Playlist = updateResponse.data.data!;
            setPlaylist(playlist);
            reset(playlist);

            const items: ResourceItem[] = playlist.items ?? [];
            setResourceItems(items);

            dispatch( actionCreator.playlistSetCurrent( playlist ) );

            setIsLoadingPlaylistItems(false);

        }
        catch(error) {
            setIsLoadingPlaylistItems(false);
        }
        
    } 

    const _onChangeResourceTime = async (id: string, duration: number) => {

        setIsLoadingPlaylistItems(true);

        try {

            const playlistStoreResourceResponse: AxiosResponse<ApiResponse<Playlist>> = await _resourcePlayListService.update(id, {
                duration: duration,
            });
            
            const playlist: Playlist = playlistStoreResourceResponse.data.data!;
            setPlaylist(playlist);

            const items: ResourceItem[] = playlist.items ?? [];
            setResourceItems(items);

            setPlaylistDurationSeconds(_getPlaylistTotalTime(playlist));
            setIsLoadingPlaylistItems(false);

        }
        catch(error) {
            setIsLoadingPlaylistItems(false);
        }

    }
      
    const _renderPlaylistItems = () => {
        
        if(!resourceItems) return;

        return(
            resourceItems.map( (item: ResourceItem, index: number) => {

                return(
                    <PlaylistDetailMediaItem
                        key={item.id}
                        index={index}
                        id={`${item.id}`}
                        duration={item.duration}
                        resource={item.resource}
                        moveCard={moveCard}
                        handleUpdateOrder={_handleUpdateOrder}
                        setIsDeletingMediaItem={setIsDeletingMediaItem}
                        setDeleteResourceItemId={setDeleteResourceItemId}
                        onChangeResourceTime={_onChangeResourceTime}
                    />
                )

            })
        )
    }

    const _renderPlaylistMediaItemSkeleton = () => {
        return Array.from({length: 10}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="col-12">
                    <PlaylistMediaItemSkeleton />
                </div>
            );
        });
    }

    const _handleRemovesPlaylistResourceItem = async() => {

        if(!deleteResourceItemId) return;

        setIsLoadingPlaylistItems(true);

        try {

            const deleteMediaResponse: AxiosResponse<ApiResponse<Playlist>> = await _resourcePlayListService.delete(deleteResourceItemId);
            const playlist: Playlist = deleteMediaResponse.data.data!;

            const items: ResourceItem[] = playlist.items ?? [];

            setPlaylist(playlist);
            reset(playlist);
            setResourceItems(items);
            
            dispatch( actionCreator.playlistSetCurrent( playlist ) );
            
            setIsLoadingPlaylistItems(false);
            setIsDeletingMediaItem(false);
            setDeleteResourceItemId(null);

            setPlaylistDurationSeconds(_getPlaylistTotalTime(playlist));
            
        } catch (error) {
            setIsLoadingPlaylistItems(false)

            setIsDeletingMediaItem(false)
            setDeleteResourceItemId(null)
        }

    }

    const _renderMedia = () => {

        return(
            <>
                <div className="d-flex align-items-center">
                    <div className="ml-auto">
                        <button
                            className={`btn btn-sm btn-cactus ${mediaReducer.isLoading ? "btn-cactus--loading" : null}`}
                            onClick={() => setCreatingLibrary(true)}
                            disabled={mediaReducer.isLoading}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{ verticalAlign: 'inherit' }} />
                        </button>
                    </div>
                </div>

                {_renderMediaInputAndTags()}

                <div className="mt-4 playlist-media-list">
                    <ul>
                        {_renderMediaList()}
                    </ul>
                </div>

            </>
        );

    }

    const _renderWidgets = () => {

        return(
            <>
                <div className="d-flex align-items-center">
                    <div className="ml-auto">
                        <button
                            className={`btn btn-sm btn-cactus ${widgetReducer.isLoading ? "btn-cactus--loading" : null}`}
                            onClick={() => setIsOpenWidgetDesigner(true)}
                            disabled={widgetReducer.isLoading}
                        >
                            <FontAwesomeIcon icon={faPlus} style={{ verticalAlign: 'inherit' }} />
                        </button>
                    </div>
                </div>

                {_renderWidgetInputAndTags()}

                <div className="mt-4 playlist-media-list">
                    <ul>
                        {_renderWidgetList()}
                    </ul>
                </div>
            </>
        );

    }

    const _renderMediaList = () => {

        if(mediaReducer.isLoading) return _renderPlaylistMediaItemSkeleton();

        let allMedia = medias?.filter((item: Media) => {
            return ifShowMediabyFilter(item, mediaFilterSelected);
        }).map( (media: Media, index:number) => {
            return(
                <PlaylistResourceItem 
                    key={index} 
                    resource={media} 
                    handleAddResource={_handleAddResourceToPlaylist}
                    handleDragMediaItems={_handleDragMediaItems}
                />
            )
        }) ?? [];

        if(allMedia.length === 0) {
            return(
                <li>
                    {t('No Result')}
                </li>
            )
        }

        return(
            <InfiniteScroll
                pageStart={mediaPagination?.from}
                loadMore={() => {
                    _fetchLibraries(mediaPagination?.current_page ? mediaPagination?.current_page + 1 : 1);
                }}
                hasMore={mediaPagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
                useWindow={false}
                getScrollParent={() => document.querySelector("#right-panel") }
            >
                {allMedia}
            </InfiniteScroll>
        );

    }

    const _renderWidgetList = () => {

        if(widgetReducer.isLoading) return _renderPlaylistMediaItemSkeleton();

        let allWidgets = widgets.map( (widget: Widget, index: number) => {
            return(
                <PlaylistResourceItem 
                    key={index} 
                    resource={widget} 
                    handleAddResource={_handleAddResourceToPlaylist}
                    handleDragMediaItems={_handleDragMediaItems}
                />
            )
        });

        if(allWidgets.length === 0) {
            return(
                <li>
                    {t('No Result')}
                </li>
            )
        }

        return(
            <InfiniteScroll
                pageStart={widgetPagination?.from}
                loadMore={() => {
                    _fetchWidgets(widgetPagination?.current_page ? widgetPagination?.current_page + 1 : 1);
                }}
                hasMore={widgetPagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
                useWindow={false}
                getScrollParent={() => document.querySelector("#right-panel") }
            >
                {allWidgets}
            </InfiniteScroll>
        );

    }

    const _renderTitle = () => {

        if(isLoading) {
            return(
                <SkeletonTheme color={process.env.REACT_APP_SKELETON_COLOR} highlightColor={process.env.REACT_APP_SKELETON_HIHGLIGHTS_COLOR}>
                    <div>
                        <Skeleton className="col-12" height={24} />
                    </div>
                </SkeletonTheme>
            );
        }

        const playlistName: string = playlist?.name ?? '';

        return(
            <div className="row">
                <div className="col-12 col-md-8">
                    <DashboardTitle title={`${t('Playlist')}: ${playlistName}`} isLoading={isLoading}  />
                </div>
                <div className="col-12 col-md-4 text-right mt-2">
                    <div>
                        <strong> { t('Total_time')}: {' '}</strong>{ playlistDuration }
                    </div> 
                </div>
            </div>
        );

    }

    const _renderDropzone = () => {

        let loading;
        if(isLoadingPlaylistItems) loading = <PlaylistDropZoneLoading />;

        let dragging;
        if(isDraggingMediaItems) dragging = <PlaylistDropZoneDropItem />;
        
        let zone;
        if(playlist) {
            
            const items: ResourceItem[] = playlist.items ?? [];

            if(items.length > 0) zone = _renderPlaylistItems();
            else if(!isLoading && !isDraggingMediaItems) zone = <PlaylistDropZoneEmpty />;

        }

        return(
            <>
                {loading}
                {dragging}
                {zone}
            </>
        );

    }

    const _renderRightPanelBody = () => {

        if(rightPanelNavLink === 0) {
            return(
                <>
                    {_renderMedia()}
                </>
            );
        }
        else if(rightPanelNavLink === 1) {
            return(
                <>
                    {_renderWidgets()}
                </>
            );
        }

        return null;

    }

    return(
        <DashboardWrapper rightColumn={true}>
            <DndProvider backend={HTML5Backend} debugMode={process.env.NODE_ENV === "development"}>
                <div className="d-flex">
                    
                    <div id="center-panel">
                        
                        {_renderTitle()}

                        {_renderScreenDetailBody()}

                        <div id="playlist-medias">

                            {_renderDropzone()}

                        </div>
                    </div>

                    <RightPanel>

                        <ul className="nav nav-tabs d-flex">
                            
                            <li className="nav-item flex-1">
                                <a 
                                    className={`nav-link text-center ${rightPanelNavLink === 0 ? "active" : null}`}
                                    href="!#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setRightPanelNavLink(0);
                                    }}
                                >
                                    {t('Medias')}
                                </a>
                            </li>
                            
                            <li className="nav-item flex-1">
                                <a 
                                    className={`nav-link text-center ${rightPanelNavLink === 1 ? "active" : null}`}
                                    href="!#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setRightPanelNavLink(1);
                                    }}
                                >
                                    {t('Widgets')}
                                </a>
                            </li>
                            
                        </ul>
                        
                        <div className="mt-3">
                            {_renderRightPanelBody()}
                        </div>

                    </RightPanel>

                </div>
                
                <ModalDeleteConfirm
                    show={deletingPlaylist}
                    title={t('Delete_playlist')}
                    onCloseFunction={setDeletingPlaylist}
                    onCloseFunctionParameter={false}
                    entityTranslation={t('playlist')}
                    buttonLoading={isUpdatingForm}
                    onClickFunctionButton={_deletePlaylist}
                    buttonText={t('Delete')}
                />
                
                <ModalDeleteConfirm 
                    show={isDeletingMediaItem}
                    title={ t('Delete_resource_item') }
                    onCloseFunction={setIsDeletingMediaItem}
                    onCloseFunctionParameter={false}
                    overrideEntityTranslationAbove={ t('Are_you_sure_to_delete_current_m_') }
                    entityTranslation={ t('resource_item_playlist') }
                    buttonLoading={isLoadingPlaylistItems}
                    onClickFunctionButton={_handleRemovesPlaylistResourceItem}
                    buttonText={ t('Delete') }
                />

                <CactusModal 
                    show={creatingLibrary}
                    title={t('New_library')}
                    onClose={() => {
                        setCreatingLibrary(false);
                    }}
                    width="70vw"
                    disableOnClose={disableOnClose}
                >
                    <CreateLibrary
                        disableOnClose={(value: boolean) => {
                            setDisableOnClose(value);
                        }}
                        handleNewMedia={(media: Media) => {
                            dispatch( actionCreator.mediaCreate(media) );
                        }}
                    />
                </CactusModal>

                <WidgetDesignerRightPanel
                    isOpen={isOpenWidgetDesigner}
                    onClose={() => setIsOpenWidgetDesigner(false)}
                    disableSubmitButton={isWidgetLoading}
                    createWidget={(widget: Widget) => {
                        dispatch( 
                            actionCreator.widgetCreate(widget, () => {
                                setIsOpenWidgetDesigner(false);
                            })
                        );
                    }}
                />

            </DndProvider>
        </DashboardWrapper>
    );

};

export default React.memo(PlaylistDetail);
