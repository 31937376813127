import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Media from '../../models/Media';
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import MediaService from "../../services/use_case/media/MediaService";
import ApiResponse from '../../services/ApiResponse';
import {
    DropzoneItem,
    DropzoneLayout,
} from "../index";
interface CreateLibraryProps {
    disableOnClose?: (value: boolean) => void;
    handleNewMedia?: (media: Media) => void;
};

const CreateLibrary: React.FC<CreateLibraryProps> = (props: CreateLibraryProps) => {

    const [t] = useTranslation();
    const mediaService: MediaService = new MediaService();
    const dropzoneEl = useRef<any>();

    useEffect(() => {

        let dropzone = dropzoneEl.current;

        dropzone.querySelector(".dzu-dropzone").addEventListener('dragover', _onDragOver);
        dropzone.querySelector(".dzu-dropzone").addEventListener('dragleave', _onDragLeave);

        return () => {
            dropzone.querySelector(".dzu-dropzone").removeEventListener('dragover', _onDragOver);
            dropzone.querySelector(".dzu-dropzone").removeEventListener('dragleave', _onDragLeave);
        }

    }, []);

    const _onDragOver = () => {
        dropzoneEl.current.querySelector(".dzu-dropzone").classList.add("dzu-dropzone--active");
    }

    const _onDragLeave = () => {
        dropzoneEl.current.querySelector(".dzu-dropzone").classList.remove("dzu-dropzone--active");
    }

    // specify upload params and url for your files
    const _getUploadParams = (file: IFileWithMeta) => {
        
        const body = new FormData();
        body.append('file', file.file);
        body.append('name', file.meta.name);

        let lang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? "en";

        return {
            url: `${mediaService.baseURL}/medias`,
            headers: {
                "Authorization": `Bearer ${mediaService.token}`,
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': lang,
            },
            body,
        }
    }

    const _handleChangeStatus = (file: IFileWithMeta, status: StatusValue) => {
        if(status === "done" && file.meta.percent === 100) {
            try {
                let responseStr: string = file.xhr?.response ?? "";
                let response: ApiResponse<Media> = JSON.parse(responseStr);
                let media: Media | null = response.data;
                if(media) props.handleNewMedia?.(media);
            }
            catch(error) { }
        }
    }

    return(
        <div className="dropzone" ref={dropzoneEl}>
            <Dropzone
                accept="image/*,video/*"
                getUploadParams={_getUploadParams}
                PreviewComponent={DropzoneItem}
                onChangeStatus={_handleChangeStatus}
                multiple={false}
                autoUpload={true}
                SubmitButtonComponent={() => null}
                inputContent={t('dropzone_text')}
                inputWithFilesContent="+"
                LayoutComponent={DropzoneLayout}
                styles={{
                    dropzone: {
                        minHeight: '40vh',
                        border: '1px dashed rgba(0, 0, 0, .5)',
                    },
                    dropzoneActive: {
                        backgroundColor: 'transparent',
                    },
                }}
            />
        </div>
    );

};

export default React.memo(CreateLibrary);
