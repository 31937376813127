import React, { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import Media, { MediaSize, getMediaDuration } from '../../models/Media';
import Widget from '../../models/Widget';
import { DragItem } from '../PlaylistResourceItem/PlaylistResourceItem'
import { PlaylistMediaItemTypes } from '../../containers/PlaylistDetail/PlaylistDetail';
import ResourceItem from '../ResourceItem/ResourceItem';
import { useDebounce } from "react-use";


interface PlaylistDetailMediaItemProps {
    duration: number;
    resource: Media | Widget;
    handleUpdateOrder: Function;
    setIsDeletingMediaItem: Function;
    setDeleteResourceItemId: Function;
    id: string;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    onChangeResourceTime?: (id: string, duration: number) => void;
};


const PlaylistDetailMediaItem: React.FC<PlaylistDetailMediaItemProps> = (props: PlaylistDetailMediaItemProps) => {

    const resource: Media | Widget = props.resource;

    const [t] = useTranslation();
    const ref = useRef<HTMLDivElement>(null)
    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
    const [mediaTime, setMediaTime] = useState<number>(props.duration);

    const id: string = props.id;

    const [, ] = useDebounce(() => {
        
        if(isFirstTime) {
            setIsFirstTime(false);
        }
        else {
            
            const id: string = props.id;

            props.onChangeResourceTime?.(id, mediaTime);

        }

    }, 500, [mediaTime]);
    
    
    const [ , drop] = useDrop({
        accept: PlaylistMediaItemTypes.CARD,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
              return
            }

            const dragIndex = item.index
            const hoverIndex = props.index
            
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
              return
            }
      
            // Time to actually perform the action
            props.moveCard(dragIndex, hoverIndex)
            
            item.index = hoverIndex
      
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        })
        
    });

    const [{ isDragging }, drag] = useDrag({
        item: { 
            type: PlaylistMediaItemTypes.CARD,
            data: resource,
            id: resource.order,
            index: props.index
        },
        end: (item: DragItem | undefined, monitor: DragSourceMonitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {

                if(item.data?.id) props.handleUpdateOrder(item.data.id);
                
            }
        },
        collect: (monitor) => ({
          isDragging: !!monitor.isDragging()
        })
    })

    const style = {
        opacity :  isDragging ? 0 : 1,
        cursor: 'move',
    }

    drag(drop(ref))
    
    const _handleModalRemovePlaylistResourceItem = (id: string) => {

        props.setIsDeletingMediaItem(true)
        props.setDeleteResourceItemId(id)
        
    }

    const _handleChangeMediaTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        const duration: number = Number(event.target.value);
        setMediaTime(duration);
    }

    const _renderLeftImage = () => {
        
        return(
            <ResourceItem
                resource={resource}
                originalMediaSize={MediaSize.small}
                height="100%"
                widgetSize="2x"
            />
        );

    }

    return(
        <div 
            ref={ref}
            className="card flex-row mt-2"
            style={style}
        >
            <div style={{ flexBasis: '100px', maxHeight: '100px', height: '100px' }}>
                
                {_renderLeftImage()}
           
            </div>
            <div className="card-body playlist-detail-media-item" style={{ flex: '1' }}>

                <div className="playlist-detail-media-item__name w-100">
                    <div className="playlist-item__content">
                        <div className="mr-3">{ resource.name ?? "" }</div>
                        
                        <div className="pr-5">

                            <label className="w-100 m-0">
                                {/* <span className="form__label" style={{ fontSize: '.7rem' }}>{t('Media Time')}</span> */}
                                <div className="d-flex mr-1">
                                    <input 
                                        type="number"
                                        min="0"
                                        className="form-control--dark py-1"
                                        value={mediaTime}
                                        onChange={_handleChangeMediaTime}
                                        style={{ maxWidth: '80px' }}
                                        disabled={resource.mime_type.startsWith("video/")}
                                    />
                                    <div 
                                        className="px-2 bg-gray-dark color-gray-light"
                                        style={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px', border: '1px solid #373637', borderLeft: 'none' }}
                                    >
                                        s
                                    </div>
                                </div>
                            </label>

                        </div>

                    </div>
                </div>
                <div className="playlist-detail-media-item__options h-100 d-flex align-items-center">
                    <i 
                        className="iconsminds-close pointer" 
                        onClick={ () => _handleModalRemovePlaylistResourceItem(id) } 
                    ></i>
                </div>
            </div>

        </div>
    );

};

export default React.memo(PlaylistDetailMediaItem);
