import { AxiosResponse } from "axios";
import ActionType from "./Action";
import * as actionTypes from './types';
import ScreenGroupService from "../services/use_case/screengroup/ScreenGroupService";
import ScreenGroupResponse from "../services/use_case/screengroup/ScreenGroupResponse";
import ScreenGroup from "../models/ScreenGroup";
import CancelRequestError from "../services/CancelRequestError";

let screenGroupService: ScreenGroupService;

/*
 * SCREEN
 */
export const screenGroupFetch = (page: number = 1, search: string = "") => {
    
    return async (dispatch: any, getState: any) => {
        
        dispatch( screenGroupCancelRequest() );
        if(page === 1) dispatch( screenGroupChangeValue('isLoading', true) );
        
        try {
            let response: AxiosResponse<ScreenGroupResponse> = await screenGroupService.fetch(page, search);
            let screenGroupResponse: ScreenGroupResponse = response.data;

            if(page === 1) dispatch( screenGroupFetchResponse(screenGroupResponse) );
            else dispatch( screenGroupFetchNextResponse(screenGroupResponse) );
        }
        catch(error) {
            if( !(error instanceof CancelRequestError) ) dispatch( screenGroupChangeValue('isLoading', false) );
        }

    };

}

export const screenGroupFetchResponse = (payload: ScreenGroupResponse): ActionType<ScreenGroupResponse> => {
    return {
        type: actionTypes.SCREENGROUP_FETCH,
        payload,
    }
}

export const screenGroupFetchNextResponse = (payload: ScreenGroupResponse): ActionType<ScreenGroupResponse> => {
    return {
        type: actionTypes.SCREENGROUP_FETCH_NEXT,
        payload,
    }
}

export const screenGroupCancelRequest = () => {

    return async () => {
        screenGroupService?.cancelRequest();
        screenGroupService = new ScreenGroupService();
    };

}

export const screenGroupUpdate = (id: string, screenGroup: ScreenGroup | FormData) => {

    return async (dispatch: any, getState: any) => {
    
        try {
            await new ScreenGroupService().update(id, screenGroup);
        }
        catch(error) {
            
        }

    };

}

export const screenGroupChangeValue = (key: string, value: any): ActionType<any> => {
    return {
        type: actionTypes.SCREENGROUP_CHANGE_VALUE,
        payload: {
            key, 
            value
        },
    }
}