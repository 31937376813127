import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import ScreenGroupService from '../../services/use_case/screengroup/ScreenGroupService';
import ApiResponse from '../../services/ApiResponse';
import { ScreenGroupInputs } from './ScreenGroup';
import ScreenGroup from '../../models/ScreenGroup';
import ScreenModel from '../../models/Screen';
import * as actionCreator from "../../actions";

import { 
    CactusLabelInput, 
    CactusModal, 
    DashboardTitle, 
    DashboardWrapper, 
    ModalDeleteConfirm, 
    ScreenItem, 
    SearchDebounce
} from '../../components';
import InfiniteScroll from 'react-infinite-scroller';
import ScreenItemSkeleton from '../../components/ScreenItem/ScreenItemSkeleton';
import { IconLoader } from '../../assets/icons/IconLoader';
import { colors } from 'react-select/src/theme';

interface ScreenGroupDetailProps {};

const ScreenGroupDetail: React.FC<ScreenGroupDetailProps> = (props: ScreenGroupDetailProps) => {

    const [t] = useTranslation();

    const _screenGroupService:ScreenGroupService = new ScreenGroupService()

    let { id: screenGroupId } = useParams<{id:string}>() 
    const [isLoading, setisLoading] = useState(false)
    const [isAttachingForm, setisAttachingForm] = useState(false)
    const [deletingScreenGroup, setdeletingScreenGroup] = useState(false)
    const [screenGroup, setscreenGroup] = useState<ScreenGroup>()
    const [attachedScreens, setAttachedScreens] = useState<ScreenModel[]>([]);
    const [title, settitle] = useState<string>(t('Screen Group'))
    const [search, setSearch] = useState<string>('');
    const [attachingScreen, setAttachingScreen] = useState(false);
    const [detachingScreen, setDetachingScreen] = useState(false);
    const [isDetachingScreenForm, setisDetachingScreenForm] = useState(false);
    const [selectedScreenIds, setSelectedScreenIds] = useState<string[]>([]);
    const [selectedScreenIdToDelete, setSelectedScreenIdToDelete] = useState<string | null>(null);

    const screenResponse = useSelector( (store: any) => store.screenReducer );
    
    const isScreenLoading = screenResponse?.isLoading
    const pagination: any = screenResponse?.data;
    const data: any = pagination?.data ?? [];

    let history = useHistory()
    const dispatch = useDispatch();

    const { reset } = useForm<ScreenGroupInputs>({
        defaultValues: {
            ...screenGroup
        }
    });

    useEffect(() => {
        _fetchScreens(1);
        _getScreenGroup(screenGroupId)
        return () => {
            _screenGroupService.cancelRequest()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getScreenGroup = async( id:string ) => {
        setisLoading(true)

        try {
            const screenGroupResponse:AxiosResponse<ApiResponse<ScreenGroup>> = await _screenGroupService.show(id);
            const screenGroup:ScreenGroup = screenGroupResponse.data.data!
            setisLoading(false)
            setscreenGroup(screenGroup)
            setAttachedScreens(screenGroup.screens ?? [])
            reset(screenGroup)
            settitle(t('Screen Group') + ': ' + screenGroup?.name)
        } catch (error) {
            history.replace('/screengroups')
        }

    }

    const _detachScreen = async() => {
        if (!selectedScreenIdToDelete) return;
        setDetachingScreen(true)
        try {
            const response = await _screenGroupService.detachScreen(screenGroupId, [selectedScreenIdToDelete])
            if (response.status === 200) {
                setAttachedScreens(prevScreens => prevScreens.filter(screen => screen.id !== selectedScreenIdToDelete));
            }
            setDetachingScreen(false)
            //_getScreenGroup(screenGroupId)
        } catch (error) {
            setDetachingScreen(false)
        }
    }

    const _fetchScreenInScreenGroup = (page?: number) => {
        dispatch( actionCreator.screenCancelRequest() );
        //dispatch( actionCreator.screenFetch(page, search) );
        
    }

    const _handleOpenAttachScreen = () => {
        setAttachingScreen(true)
    }

    const _handleOpenDetachScreen = (screen_id: string) => {
        setSelectedScreenIdToDelete(screen_id)
        setDetachingScreen(true)
    }

    const _handleAttachScreen = async () => {
        try {
            console.warn('selectedScreenIds', selectedScreenIds)
            const response = await _screenGroupService.attachScreen(screenGroupId, selectedScreenIds);
            if(response.status === 200) {
                setAttachedScreens(prevScreens => [...prevScreens, ...selectedScreenIds.map(id => data.find((screen: ScreenModel) => screen.id === id))]);
            }
            setAttachingScreen(false);
            //_getScreenGroup(screenGroupId);
        } catch (error) {
            setAttachingScreen(false);
        }
        finally {
            setSelectedScreenIds([]);
        }
    };

    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className="btn btn-cactus mt-2"
                disabled={isAttachingForm ?? false}
                onClick={_handleAttachScreen}
            >
                {t('Attach_screens')}
            </button>
        );
    }

    const _renderAttachedScreens = () => {

        if(isLoading) return null;

        /*const filteredScreens = screenGroup?.screens?.filter((screen: ScreenModel) => 
            screen.name.toLowerCase().includes(search.toLowerCase())
        ) ?? [];*/

        //text
        const filteredScreens = attachedScreens.filter((screen: ScreenModel) =>
            screen.name.toLowerCase().includes(search.toLowerCase())
        );
    
        // Mapear las pantallas filtradas a elementos JSX
        let screens = filteredScreens.map((screen: ScreenModel, index: number) => {

        //let screens = attachedScreens.map((screen: ScreenModel, index: number) => {

            return(
                <div key={screen.id} className="col-2">
                    <div className="card mb-3">
                        <div className={`card-body pointer screen-item`}>
                            <i className="iconsminds-monitor"></i>
                            <span className="ml-2">{ screen.name }</span>
                            <a onClick={ () => _handleOpenDetachScreen(screen.id)}>
                                <i className="color-secondary-color iconsminds-close pointer"></i>
                            </a>
                        </div>
                        {/* <div className="screen-item__options">
                            <a className="screen-item__name" onClick={ () => _handleOpenDetachScreen(screen.id)}>
                                <i className="iconsminds-remove pointer"></i>
                            </a>
                        </div> */}
                    </div>
                </div>
            );

        }) ?? [];

        if(screens.length === 0) {
            return(
                <div className='col-12'>
                    {t('No screens attached')}
                </div>
            )
        }

        return(
            <>
                {screens}
            </>
        );
    
    }
    
    /*const _renderScreenGroupForm = () => {

        return (
            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit(_handleOnSubmit)} >
                        <div className="row">
                            <div className="col-sm-6">
                                <CactusLabelInput
                                    labelTitle={t('Name')}
                                    inputAttributes={{
                                        id: "name",
                                        name: "name",
                                        type:  "text",
                                        placeholder: t('Name'),
                                        autoComplete: "name",
                                        ref: textValidator,
                                    }}
                                    errorMessage={errors.name ? errors.name.message : null}
                                />
                            </div>
                            <div className="col-12 text-right">
                                { _renderBtnSubmit() }
                            </div>
                        </div>
                    </form>

                    <div className="col-12 text-right">
                        { _renderDeleteButton() }
                    </div>
                    
                </div>
            </div>
        )
        
    }*/

    const _renderSkeletonLoading = () => {
        return Array.from({length: 15}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="mb-3">
                    <ScreenItemSkeleton />
                </div>
            );
        });
    }

    const _fetchScreens = (page?: number) => {
        dispatch( actionCreator.screenCancelRequest() );
        dispatch( actionCreator.screenFetch(page, search) );
    }

    const _renderScreens = () => {

        if(isScreenLoading) {
            return _renderSkeletonLoading();
        }

        const associatedScreenIds = attachedScreens.map(screen => screen.id) ?? [];
        //let screens: JSX.Element[] = data?.filter((screen: ScreenModel) => !associatedScreenIds.includes(screen.id)).map((screen: ScreenModel, index: number) => {
        //let screens: ScreenModel[] = data?.map((screen: ScreenModel, index: number) => {
        let screens: JSX.Element[] = data?.filter((screen: ScreenModel) => !associatedScreenIds.includes(screen.id)).map((screen: ScreenModel, index: number) => {
            const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                const { checked, value } = e.target;
                setSelectedScreenIds(prev =>
                    checked ? [...prev, value] : prev.filter(id => id !== value)
                );
            };
        
            return(
                <div key={screen.id} className="card mb-3">
                    <div className="card-body screen-item">
                        <div className="screen-item__name" >
                            <i className="iconsminds-monitor"></i>
                            <span className="ml-2">{ screen.name }</span>
                        </div>
                        <div className="screen-item__options">
                            <input type='checkbox'
                                value={screen.id}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                    </div>
                </div>
            )
        }) ?? [];

        if(screens.length === 0) {
            return(
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
                        {t('No Result')}
                    </div>
                </div>
            )
        }

        return(
            <InfiniteScroll
                pageStart={pagination?.from}
                loadMore={() => {
                    _fetchScreens(pagination?.current_page ? pagination?.current_page + 1 : 1)
                }}
                hasMore={pagination?.next_page_url !== null}
                loader={
                    <div key="loading" className="d-flex justify-content-center">
                        <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
                    </div>
                }
            >
                <div className="col-12">
                    {screens}
                </div>
            </InfiniteScroll>
        );

    }
    

    return(
        <DashboardWrapper>
            <div className="row">
                <div className="col-12 col-md-6">
                    <DashboardTitle 
                        title={title}
                        isLoading={isLoading}
                        withRefreshIcon={!isLoading}
                        refreshIconCallBack={() => {
                            _fetchScreenInScreenGroup(1);
                        }}
                    />

                    <SearchDebounce
                        placeholder={`${t('Name')}`}
                        borderColor="transparent"
                        onChange={(text: string) => setSearch(text)}
                        //debounceFinish={(text: string) => _fetchScreenInScreenGroup(1)}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex mt-1">
                        <button
                            className="btn btn-cactus ml-auto"
                            disabled={isLoading}
                            onClick={ () => _handleOpenAttachScreen() }
                        >
                            { t('Attach_screens') }
                        </button>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                {_renderAttachedScreens()}
            </div>

            <CactusModal 
                show={attachingScreen}
                title={ t('Attach_screens') }
                onClose={() => {
                    setAttachingScreen(false)
                    //setstoredScreen(null)
                }}
                minWidth="500px"
            >
                <div style={{maxHeight: '50vh', overflow:'auto'}}>
                    
                    {_renderScreens()}
                    
                </div>

                <div className="col-12 text-right">
                    { _renderBtnSubmit() }
                </div>
            </CactusModal>
            <ModalDeleteConfirm 
                show={detachingScreen}
                title={t('Detach_screen')}
                onCloseFunction={setDetachingScreen}
                onCloseFunctionParameter={false}
                overrideEntityTranslationAbove={ t('Are_you_sure_to_detach_current_f_') }
                entityTranslation={t('screen')}
                buttonLoading={isDetachingScreenForm}
                onClickFunctionButton={_detachScreen}
                buttonText={t('Detach')}
            />
        </DashboardWrapper>
    );

};

export default React.memo(ScreenGroupDetail);
