import ResourceItem from "./ResourceItem";
import Option from "./Option";

export default interface Playlist {
    id: string;
    name: string;
    total_time: number;
    user_id: string;
    created_at: string;
    updated_at: string;

    // Resources
    items?: ResourceItem[];
};

export function getOption(playlist: Playlist): Option { 
    return {
        value: playlist.id,
        type: 'App\\Models\\Playlist',
        label: playlist.name,
    }
}
